.MuiSvgIcon-root-85 {
  margin-bottom: 10px !important;
}
.MuiTab-labelContainer-179 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.react-swipeable-view-container {
  transition-duration: 600ms !important;
}
