.col-grid-1 {
  width: calc(1 * (100% / 12)) !important;
}

.col-grid-2 {
  width: calc(2 * (100% / 12)) !important;
}

.col-grid-3 {
  width: calc(3 * (100% / 12)) !important;
}

.col-grid-4 {
  width: calc(4 * (100% / 12)) !important;
}

.col-grid-5 {
  width: calc(5 * (100% / 12)) !important;
}

.col-grid-6 {
  width: calc(6 * (100% / 12)) !important;
}

.col-grid-7 {
  width: calc(7 * (100% / 12)) !important;
}

.col-grid-8 {
  width: calc(8 * (100% / 12)) !important;
}

.col-grid-9 {
  width: calc(9 * (100% / 12)) !important;
}

.col-grid-10 {
  width: calc(10 * (100% / 12)) !important;
}

.col-grid-11 {
  width: calc(11 * (100% / 12)) !important;
}

.col-grid-12 {
  width: calc(12 * (100% / 12)) !important;
}

.grid-padding-left {
  padding-left: 15px !important;
  padding-right: 0 !important;
}

.grid-padding-right {
  padding-left: 0 !important;
  padding-right: 15px !important;
}

.ag-cell-label-container {
  height: 100%;
  font-size: 16px !important;
  font-weight: 400;
  cursor: pointer;
  color: #000000;
  opacity: 0.75;
}

.ag-header-cell-sorted-asc {
  opacity: 1 !important;
}

.ag-header-cell-sorted-desc {
  opacity: 1 !important;
}

.ag-cell {
  font-size: 14px !important;
  border: 0 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ag-cell:focus {
  border: none !important;
  outline: none;
}

.ag-row-selected {
  border-bottom: 2px solid #2ba69f !important;
}

.ag-row {
  width: 100% !important;
}

.ag-cell,
.ag-header-cell,
.ag-header-group-cell {
  position: initial !important;
}

.ag-layout-normal .ag-row {
  width: auto;
}

.ag-theme-material .ag-header {
  font-weight: 300 !important;
}

.ag-theme-material {
  font-weight: 300 !important;
}

.ag-paging-panel {
  display: none;
  height: 0px !important;
}

.ag-paging-page-summary-panel {
  display: none !important;
}

.ag-paging-row-summary-panel {
  display: none !important;
}

.ag-cell-with-height {
  height: auto;
}

.ag-header-row {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}

.ag-paging-panel {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}

.ag-theme-material .ag-row-drag .ag-icon-row-drag {
  background-image: url('../../assets/images/icon_drag_indicator.svg');
  opacity: 0.5;
  background-size: 30px 30px;
  height: 40px;
  width: 40px;
}

.ag-theme-material .ag-row-drag {
  margin-right: 55px;
  width: 18px;
  height: 18px;
}

.padding-column-drag {
  padding-left: 85px !important;
}

.ag-theme-material .ag-icon-checkbox-checked:empty {
  background-image: url('../../assets/images/check_box.png');
  background-size: 24px 24px;
}

.ag-body-horizontal-scroll-viewport {
  overflow-y: hidden;
}

.ag-body-viewport.ag-layout-normal {
  overflow-y: overlay;
}
