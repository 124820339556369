.react-checkbox-tree label {
  margin-bottom: 0;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.react-checkbox-tree button,
.rct-collapse,
.rct-checkbox,
.rct-node-icon,
.rct-title {
  padding: 0;
}

.rct-text {
  margin-left: -8px;
  height: 45px;
}

.react-checkbox-tree ol ol {
  padding-left: 30px;
  margin-top: -13px;
}
