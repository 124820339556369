.swal-stretched {
  max-width: calc(100vw - 150px);
  min-width: calc(100vw - 750px);
  width: auto;
}

.swal-content {
  padding-right: 30px;
  padding-left: 30px;
}

.swal-footer {
  background-color: #f1f9f9 !important;
}

.swal-botao-confirm,
.swal-botao-cancel {
  color: white;
  font-size: 14px;
  font-family: Roboto;
  font-weight: normal !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.24);
}

.swal-botao-confirm,
.swal-botao-confirm:hover {
  background-color: #124268 !important;
}

.swal-botao-cancel,
.swal-botao-cancel:hover {
  background-color: #f33a30 !important;
}
