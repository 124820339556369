body, html {
  height: 100%;
  margin:0px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-weight:300 !important
}


#navbar {
  max-height: 0;
  overflow: auto;
  -webkit-transition: max-height 220ms;
  transition: max-height 220ms;
  /* Set our transitions up. */
}
#navbar.slideIn {
  max-height: 0;
}

#navbar.slideOut {
  max-height: 100px;
}

#button {
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
}
#button.menuOpen {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.run-animation-close {
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  color: white;
  font-size: 14px;
  opacity: 0;
}

.run-animation-open {
  -webkit-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
  color: white;
  font-size: 14px;
  opacity: 1;
}
#run-animation-close-logo {
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  -webkit-transition-delay: 0.1 s;
          transition-delay: 0.1 s;
  color: white;
  font-size: 14px;
  opacity: 1;
}

#run-animation-open-logo {
  color: white;
  font-size: 14px;
  opacity: 0;
}
.MuiListItem-selected-220 {
  background-color: #124268;
}
#iconRight {
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
#iconDown {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.col-grid-1 {
  width: calc(1 * (100% / 12)) !important;
}

.col-grid-2 {
  width: calc(2 * (100% / 12)) !important;
}

.col-grid-3 {
  width: calc(3 * (100% / 12)) !important;
}

.col-grid-4 {
  width: calc(4 * (100% / 12)) !important;
}

.col-grid-5 {
  width: calc(5 * (100% / 12)) !important;
}

.col-grid-6 {
  width: calc(6 * (100% / 12)) !important;
}

.col-grid-7 {
  width: calc(7 * (100% / 12)) !important;
}

.col-grid-8 {
  width: calc(8 * (100% / 12)) !important;
}

.col-grid-9 {
  width: calc(9 * (100% / 12)) !important;
}

.col-grid-10 {
  width: calc(10 * (100% / 12)) !important;
}

.col-grid-11 {
  width: calc(11 * (100% / 12)) !important;
}

.col-grid-12 {
  width: calc(12 * (100% / 12)) !important;
}

.grid-padding-left {
  padding-left: 15px !important;
  padding-right: 0 !important;
}

.grid-padding-right {
  padding-left: 0 !important;
  padding-right: 15px !important;
}

.ag-cell-label-container {
  height: 100%;
  font-size: 16px !important;
  font-weight: 400;
  cursor: pointer;
  color: #000000;
  opacity: 0.75;
}

.ag-header-cell-sorted-asc {
  opacity: 1 !important;
}

.ag-header-cell-sorted-desc {
  opacity: 1 !important;
}

.ag-cell {
  font-size: 14px !important;
  border: 0 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ag-cell:focus {
  border: none !important;
  outline: none;
}

.ag-row-selected {
  border-bottom: 2px solid #2ba69f !important;
}

.ag-row {
  width: 100% !important;
}

.ag-cell,
.ag-header-cell,
.ag-header-group-cell {
  position: static !important;
  position: initial !important;
}

.ag-layout-normal .ag-row {
  width: auto;
}

.ag-theme-material .ag-header {
  font-weight: 300 !important;
}

.ag-theme-material {
  font-weight: 300 !important;
}

.ag-paging-panel {
  display: none;
  height: 0px !important;
}

.ag-paging-page-summary-panel {
  display: none !important;
}

.ag-paging-row-summary-panel {
  display: none !important;
}

.ag-cell-with-height {
  height: auto;
}

.ag-header-row {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}

.ag-paging-panel {
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 2px !important;
}

.ag-theme-material .ag-row-drag .ag-icon-row-drag {
  background-image: url(/static/media/icon_drag_indicator.78310cc6.svg);
  opacity: 0.5;
  background-size: 30px 30px;
  height: 40px;
  width: 40px;
}

.ag-theme-material .ag-row-drag {
  margin-right: 55px;
  width: 18px;
  height: 18px;
}

.padding-column-drag {
  padding-left: 85px !important;
}

.ag-theme-material .ag-icon-checkbox-checked:empty {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkBAMAAAATLoWrAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAACRQTFRFAAAAK6afAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAz72uIAAAAAx0Uk5TAP9ADiUbDDsJIQcIw7PHfAAAAHlJREFUeJxjYKAuEEQCEBFGZCEBDEVQZdQQUsQQEmbBEDJgRRcSYwhEF0pgRTcerAgmJA4WamBFcsQEQyBbAqwIJhTABlcEExJlaBSUgiiCGx/AIbiAFdVDogy7oYoQjghgYEX3tihMEZJTQykLQtKEsEQtlgRANQAA/GYe9FtAn5AAAAAASUVORK5CYII=);
  background-size: 24px 24px;
}

.ag-body-horizontal-scroll-viewport {
  overflow-y: hidden;
}

.ag-body-viewport.ag-layout-normal {
  overflow-y: overlay;
}

#button{
    padding: 0px !important;
    width: 34px;
    min-width: 0px !important;
}
.MuiButton-root-344{
     padding: 10px 20px !important
 }
li{
    font-weight:300 !important  
}
p{
    font-weight:300 !important
}
button{
    font-weight:300 !important
}


#chipFiltro {
    font-weight: 500 !important;
    font-style: normal;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.swal-stretched {
  max-width: calc(100vw - 150px);
  min-width: calc(100vw - 750px);
  width: auto;
}

.swal-content {
  padding-right: 30px;
  padding-left: 30px;
}

.swal-footer {
  background-color: #f1f9f9 !important;
}

.swal-botao-confirm,
.swal-botao-cancel {
  color: white;
  font-size: 14px;
  font-family: Roboto;
  font-weight: normal !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.24);
}

.swal-botao-confirm,
.swal-botao-confirm:hover {
  background-color: #124268 !important;
}

.swal-botao-cancel,
.swal-botao-cancel:hover {
  background-color: #f33a30 !important;
}

#openIcone{
    margin-top: 5px;
    margin-left: -4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
  
    
    
    
    
.MuiSvgIcon-root-85 {
  margin-bottom: 10px !important;
}
.MuiTab-labelContainer-179 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.react-swipeable-view-container {
  -webkit-transition-duration: 600ms !important;
          transition-duration: 600ms !important;
}

.react-checkbox-tree label {
  margin-bottom: 0;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.react-checkbox-tree button,
.rct-collapse,
.rct-checkbox,
.rct-node-icon,
.rct-title {
  padding: 0;
}

.rct-text {
  margin-left: -8px;
  height: 45px;
}

.react-checkbox-tree ol ol {
  padding-left: 30px;
  margin-top: -13px;
}

.row-selected-style {
  border-bottom: 0px solid transparent !important;
  background-color: transparent !important;
}

