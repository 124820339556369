#button {
  transition-property: all;
  transition-property: transform;
  transition-duration: 300ms;
}
#button.menuOpen {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
