#button{
    padding: 0px !important;
    width: 34px;
    min-width: 0px !important;
}
.MuiButton-root-344{
     padding: 10px 20px !important
 }
li{
    font-weight:300 !important  
}
p{
    font-weight:300 !important
}
button{
    font-weight:300 !important
}
